import { Plus_Jakarta_Sans } from '@next/font/google'
import type { AppProps } from 'next/app'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'

export const plusJakartaSans = Plus_Jakarta_Sans({
	display: 'swap',
})

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<SharedLoadingIndicatorContextProvider>
			<SharedProgressLoadingIndicator />
			<PageNavigationLoadingTracker />
			<style jsx global>{`
				:root {
					--font-plus-jakarta-sans: ${plusJakartaSans.style.fontFamily};
					--font-body: var(--font-plus-jakarta-sans);
					--font-heading: var(--font-body);
				}
				html {
					font-family: ${plusJakartaSans.style.fontFamily};
				}
			`}</style>
			<Component {...pageProps} />
		</SharedLoadingIndicatorContextProvider>
	)
}

export default api.withTRPC(MyApp)
